* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}



body,
* {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
}

body {
  position: fixed;
  width: 100%;
  height: 100%;
}

textarea,
select,
input,
button {
  outline: none;
}



// Hide scrollbar for Chrome, Safari and Opera 
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

// Hide scrollbar for IE, Edge and Firefox
.scrollbar-hide {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

// width
.scrollbar-custom::-webkit-scrollbar {
  width: 10px;
  height: 30px;
}

// Track
.scrollbar-custom::-webkit-scrollbar-track {
  background: red;
  border-radius: 10px;
}

// Handle
.scrollbar-custom::-webkit-scrollbar-thumb {
  background: blue;
  max-height: 20px;
  border-radius: 10px;
}

// Handle on hover 
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.style-4::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.style-4::-webkit-scrollbar-thumb {
  background-color: #1b1f2d;
  border: 2px solid #1b1f2d;
  border-radius: 32px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.highcharts-series path {
  stroke: #151823;
}
