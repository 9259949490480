@import "node_modules/include-media/dist/include-media.scss";

$breakpoints: (
  "phone": 768px,
  "tablet": 1240px,
) !default;

// Color styles
$bg-900: #080a11;
$bg-800: #111522;
$bg-700: #191f34;
$bg-600: #212945;
$bg-500: #293456;
$bg-400: #323e67;
$bg-300: #98a4cd;
$bg-200: #bac2de;
$bg-100: #dde1ee;
$white: #ffffff;
