@import "../main.scss";



.forgot__top__standard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  padding: 30px 120px;

  @include media("<=phone") {
    padding: 24px;
  }

  .link {
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }

  .colored_link {
    text-decoration: none;
    color: #0c6ef2;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }
}

.logo {
  height: 32px;
  width: auto;
}

.forgot__form__standard {
  max-width: 345px;
  width: 100%;
  margin: 200px auto;

  h1 {
    font-size: 28px;
    font-weight: 500;
  }

  .captions {
    display: grid;
    gap: 12px;
  }

  .desc {
    margin-top: 12px;
    color: #bac2de;
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 48px;
  }

  .forgot_password {
    color: #0c6ef2;
    font-weight: 600;
    margin-top: -8px;
  }
}

.login__form__new {
  place-self: center;
  max-width: 345px;
  width: 100%;
  margin: 72px auto;

  .title {
    margin-bottom: 48px;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    color: #000;
  }

  .login__form__new__supporting__text {
    color: #66666e;
    margin-top: 16px;
    // font-weight: 500;
  }

  .login__form__new__supporting__text span {
    color: #0c6ef2;
  }
}
